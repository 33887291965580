import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://68133591095182dfcd844bb20e9a527f@o4507479625957376.ingest.us.sentry.io/4507479633297408",
  debug: false,
  environment: "staging",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  sampleRate: 0.5,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 0.8,
});